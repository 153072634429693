body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bs-body-font-size: 0.875em !important;
  --bs-border-color: #e7e7e7 !important;
}

html,
body,
#root {
  height: 100%;
  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.display-3 {
  font-size: 2rem;
}

.display-4 {
  font-size: 1.5rem;
}

.display-5 {
  font-size: 1.2rem;
}

.strong {
  font-weight: 400;
}

.form-label {
  color: #5e5e5e;
}

.text-medium {
  font-size: medium;
}

.text-primary {
  color: #4f50c7 !important;
}

.background-primary {
  background-color: #4f50c7 !important;
}

.hoverable-lightgray:hover {
  background-color: #f9f9f9 !important;
}

.hoverable-text-primary:hover {
  color: #2b2da9 !important;
}

.action-links {
  color: #212529;
}

.text-muted {
  color: #5e5e5e !important;
}

a,
.btn-link,
.nav-link {
  color: inherit;
  text-decoration: none;
}

.dropdown-item {
  color: #5e5e5e;
}

.dropdown-menu {
  --bs-dropdown-color: #5e5e5e !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #f9f9f9 !important;
  color: inherit;
}

.action-links:hover {
  color: inherit;
}

thead {
  line-height: 3rem !important;
}

thead th {
  padding-left: 9px !important;
}

table td {
  white-space: nowrap !important;
}

.text-black {
  color: black;
}

.nav-tabs .nav-link {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 1rem;
  white-space: nowrap;
}

.nav-tabs .nav-link .nav-ellipsis,
.nav-tabs .nav-link.nav-ellipsis {
  max-width: 8rem;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.nav-link-hover-color {
  background-color: #4f50ce;
}

a:hover,
.btn-link:hover,
.nav-link:hover {
  color: #4f50ce;
}

tbody > tr:last-child > td {
  border-bottom: 0;
}

.nav-tabs .nav-link.active {
  border-bottom: 2px solid #4f50ce !important;
  color: #4f50ce;
  background-color: unset;
}

@media (max-width: 992px) {
  .display-lt-md-none {
    display: none;
  }
  .display-lt-md-block {
    display: block;
  }
}

@media (min-width: 992px) {
  .display-lt-md-none {
    display: block;
  }
  .display-lt-md-block {
    display: none;
  }
}

.offcanvas {
  top: 0;
  width: 70% !important;
}

/* .breadcrumb-item + .breadcrumb-item::before {
  content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPjxwYXRoIGQ9Ik0yLjUgMEwxIDEuNSAzLjUgNCAxIDYuNSAyLjUgOGw0LTQtNC00eiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+);
} */

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

html:not([dir="rtl"]) .alert-dismissible {
  padding-right: 3.8125rem;
}

*[dir="rtl"] .alert-dismissible {
  padding-left: 3.8125rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

html:not([dir="rtl"]) .alert-dismissible .close {
  right: 0;
}

*[dir="rtl"] .alert-dismissible .close {
  left: 0;
}

.alert-primary {
  color: #4f50ce;
  background-color: #d6d2f8;
  border-color: #c6c0f5;
}

.alert-primary hr {
  border-top-color: #b2aaf2;
}

.alert-primary .alert-link {
  color: #110a4f;
}

.alert-secondary {
  color: #6b6d7a;
  background-color: #f5f6f7;
  border-color: #f1f2f4;
}

.alert-secondary hr {
  border-top-color: #e3e5e9;
}

.alert-secondary .alert-link {
  color: #53555f;
}

.alert-success {
  color: #18603a;
  background-color: #d5f1de;
  border-color: #c4ebd1;
}

.alert-success hr {
  border-top-color: #b1e5c2;
}

.alert-success .alert-link {
  color: #0e3721;
}

.alert-info {
  color: #4f50ce;
  background-color: #d6ebff;
  border-color: #c6e2ff;
}

.alert-info hr {
  border-top-color: #add5ff;
}

.alert-info .alert-link {
  color: #133864;
}

.alert-warning {
  color: #815c15;
  background-color: #feefd0;
  border-color: #fde9bd;
}

.alert-warning hr {
  border-top-color: #fce1a4;
}

.alert-warning .alert-link {
  color: #553d0e;
}

.alert-danger {
  color: #772b35;
  background-color: #fadddd;
  border-color: #f8cfcf;
}

.alert-danger hr {
  border-top-color: #f5b9b9;
}

.alert-danger .alert-link {
  color: #521d24;
}

.alert-light {
  color: #7a7b86;
  background-color: #fbfbfc;
  border-color: #f9fafb;
}

.alert-light hr {
  border-top-color: #eaedf1;
}

.alert-light .alert-link {
  color: #62626b;
}

.alert-dark {
  color: #333a4e;
  background-color: #e0e2e6;
  border-color: #d3d7dc;
}

.alert-dark hr {
  border-top-color: #c5cad1;
}

.alert-dark .alert-link {
  color: #1f232f;
}

.close-0-2-24.close {
  display: none;
}

.page-link-active {
  border-color: #4f50c7 !important;
  border-radius: 5px;
}

/* make primary button bold */
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #4f50c7;
  --bs-btn-border-color: #4f50c7;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2b2da9;
  --bs-btn-hover-border-color: #2b2da9;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2b2da9;
  --bs-btn-active-border-color: #2b2da9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5f5fd9;
  --bs-btn-disabled-border-color: #4f50ce;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: bold !important;
}

.accordion-button:not(.collapsed) {
  background-color: #4f50ce;
  color: white;
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}

@media (min-width: 992px) {
  .left-nav-width {
    width: 200px;
  }
}
/* .fa-trash:hover {
  color: #fff;
}
.fa-user-edit:hover {
  color: #4f50ce;
} */

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: #fbfbfb;
  color: var(--bs-table-hover-color);
}
.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}

.import-page-selection:hover {
  background-color: #aea7a70d;
  border-color: #e7e7e7;
}
/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .h-sm-100 {
    height: 100% !important;
  }
  .h-sm-75 {
    height: 75% !important;
  }
  .h-sm-50 {
    height: 50% !important;
  }
  .h-sm-25 {
    height: 25% !important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .h-md-100 {
    height: 100% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
  .h-md-50 {
    height: 50% !important;
  }
  .h-md-25 {
    height: 25% !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
}

textarea {
  resize: none;
}

textarea::-webkit-scrollbar {
  background-color: transparent;
}
textarea::-webkit-scrollbar-thumb {
  background-color: #5e5e5e;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: padding-box;
}

.disable-tr-row {
  background-color: #fcfcfc;
}

.table-hover > tbody > tr.disable-tr-row:hover > * {
  --bs-table-accent-bg: #fcfcfc !important;
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.invitation-dialog {
  bottom: 50px;
}

.tag-container {
  border-radius: 15px;
  border-color: #e7e7e7;
  border-style: solid;
  border-width: 1px;
  display: flex;
  justify-content: space-around;
  gap: 20px;
  padding-left: 10px;
  z-index: 100;
}

.selection-list {
  width: 200px;
  background-color: white !important;
  position: fixed;
  padding: 8px !important;
  z-index: 100;
  border-radius: 5px;
  border-color: #e7e7e7;
  border-style: solid;
  border-width: 1px;
}

.tag-input {
  color: #5e5e5e;
  display: flex;
  flex-direction: row;
  gap: 5px;
  min-height: 40px;
  max-height: 80px;
  overflow-y: auto;
  flex-wrap: wrap;
}

.delete-tag {
  cursor: pointer;
}

.delete-tag:hover {
  color: black;
}

.medium-modal {
  --bs-modal-width: 700px;
}

.link-purple-btn {
  color: #4f50c7;
  cursor: pointer;
}
.disable-table-border table tbody tr td {
  border-bottom-width: 0px;
}

.position-fixed {
  position: fixed !important;
}
.tags-area {
  display: flex;
  flex: 1;
  padding: 2px;
  max-height: 100px;
  overflow-y: auto;
}

.tags-input {
  border: none;
  outline: none;
  box-shadow: none;
  padding-left: 5px;
  display: flex;
  flex: 1;
}

.member-wrapper {
  color: black;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
}

.text-grayblack {
  color: #5e5e5e !important;
}

.initials-avatar {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 5px;
  font-size: 13px;
}

.role-selection {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-right: 10px;
}

.react-tagsinput-tag {
  border-radius: 10px !important;
  padding: 2px 10px !important;
  background-color: white !important;
  border-color: #e7e7e7 !important;
  color: #5e5e5e !important;
  font-family: "Lato", sans-serif !important;
  font-size: unset !important;
  margin-bottom: unset !important;
}

.render-tag-layout-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
  gap: 5px;
}

.react-tagsinput-tag-invalid {
  border-radius: 10px !important;
  padding: 2px 10px !important;
  background-color: white !important;
  border: 1px solid red !important;
  color: red !important;
  display: inline-block;
  font-family: "Lato", sans-serif !important;
  font-size: unset !important;
  font-weight: 400;
  margin-right: 5px;
}
.react-tagsinput-tag-invalid a::before {
  content: " ×";
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.react-tagsinput-input {
  margin-bottom: unset !important;
  margin-top: unset !important;
  padding: unset !important;
  font-family: "Lato", sans-serif !important;
  font-size: unset !important;
}

.react-tagsinput-remove {
  margin-left: 5px !important;
  color: #5e5e5e !important;
  font-weight: normal !important;
}

.react-tagsinput-remove-invalid {
  margin-left: 5px !important;
  color: red !important;
  font-weight: normal !important;
}
/* .hoverable-btn{
  display: none;
}

tr:hover td .hoverable-btn{
  display: inline;
} */
.button-custom {
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.invitation-tags-container {
  display: flex;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
  border-color: #e7e7e7;
  flex: 1;
  align-items: center;
}

.invited-badge {
  background-color: #6d4b8f;
  color: white;
  border-radius: 20px;
  padding: 5px 8px;
  font-size: 13px;
  display: inline;
}

.red-color {
  color: red;
}

.form-control {
  border: 1px solid #e7e7e7;
}

.react-tagsinput-input::placeholder {
  color: #aea7a7;
  opacity: 1;
}

.react-tagsinput-input::-ms-input-placeholder {
  color: #aea7a7;
}

.form-control::placeholder {
  color: #aea7a7;
  opacity: 1; /* Firefox */
}

.form-control::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #aea7a7;
}

.form-control.is-invalid {
  background-image: none !important;
  padding-right: calc(1.5em + 0.75rem);
}

.padding-right-eye {
  padding-right: calc(1.5em + 0.75rem) !important;
}

.eye-icon {
  color: #aea7a7;
  position: absolute;
  bottom: 0px;
  right: 10px;
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
}

.eye-icon:hover {
  color: #5e5e5e;
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.failed-import-pages-container {
  height: 200px;
  width: 300px;
}

.failed-image-registration-container {
  height: 300px;
  width: 300px;
}
.failed-registration-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.title {
  font-size: 28px;
  line-height: 1.3px;
}

.border-primary {
  border-color: #4f50c7 !important;
}

.hoverable {
  border: 1px solid #4f50c7 !important;
}

.border-light-gray {
  border-color: #e7e7e7;
}

.scrollable::-webkit-scrollbar {
  background-color: transparent;
}
.scrollable::-webkit-scrollbar-thumb {
  background-color: #5e5e5e;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: padding-box;
}

.light-stroke {
  border-color: #e7e7e7;
}

.failed-sitemap-container {
  height: 200px;
  width: 200px;
}

.position-right {
  right: 20px !important;
  left: unset !important;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
}

.hover-purple:hover {
  color: #4f50c7;
}

.tooltip-inner {
  max-width: unset !important;
}
